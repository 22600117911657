import React from 'react'; 
import '../../nomatch.css'


// fix alignments with diff photo sizes and the space between 
// change text and photo content 
  

const NoMatch = () => {
  return (
    <div className='body'>
       
<div class='c'>
    <div class='_404'>404</div>
    <hr/>
    <div class='_1 margin-top-M'>THE PAGE</div>
    <div class='_2'>WAS NOT FOUND</div> 
    <hr class='margin-top-M'/>
    
    </div>
    </div>
  );
};

export default NoMatch;


